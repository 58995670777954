/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e8e25437b49f95eb-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1503d3bdd3f79a19-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e2a272b6447367f1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/be657e7655c1bafa-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/468b79e2d56358f9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b0da285322ee6896-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d3d85d86ad5c431a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/e8e25437b49f95eb-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/1503d3bdd3f79a19-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/e2a272b6447367f1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/be657e7655c1bafa-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/468b79e2d56358f9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/b0da285322ee6896-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d3d85d86ad5c431a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e8e25437b49f95eb-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1503d3bdd3f79a19-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e2a272b6447367f1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/be657e7655c1bafa-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/468b79e2d56358f9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b0da285322ee6896-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Slab_ef21a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d3d85d86ad5c431a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Slab_Fallback_ef21a0';src: local("Times New Roman");ascent-override: 89.69%;descent-override: 23.20%;line-gap-override: 0.00%;size-adjust: 116.83%
}.__className_ef21a0 {font-family: '__Roboto_Slab_ef21a0', '__Roboto_Slab_Fallback_ef21a0', system-ui, arial;font-style: normal
}.__variable_ef21a0 {--font-content: '__Roboto_Slab_ef21a0', '__Roboto_Slab_Fallback_ef21a0', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_256112';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_3_Fallback_256112';src: local("Arial");ascent-override: 109.21%;descent-override: 42.66%;line-gap-override: 0.00%;size-adjust: 93.76%
}.__className_256112 {font-family: '__Source_Sans_3_256112', '__Source_Sans_3_Fallback_256112', system-ui, arial;font-style: normal
}.__variable_256112 {--font-content: '__Source_Sans_3_256112', '__Source_Sans_3_Fallback_256112', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cb6f448281d0c282-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0cf580864ae59341-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fb3d4f9492d39101-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b3e13a4ac57b538e-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8bb90efd1b2442e0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f884d4ea94220255-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f98b5748eac51d35-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f9e3f1f7478b5cbf-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/2ffff5335cee8907-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/cfcbe5037985cfe8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7eba5a0cf69ea5af-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/e76854c588de135c-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d4f7cefda76b2fff-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d51be7479414f4fc-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3be63adebbe80e17-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e2a926bef88262a-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/86ba892d94d0e659-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Ubuntu_764742';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e94cfb95acf534f2-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Ubuntu_Fallback_764742';src: local("Arial");ascent-override: 91.32%;descent-override: 18.52%;line-gap-override: 2.74%;size-adjust: 102.06%
}.__className_764742 {font-family: '__Ubuntu_764742', '__Ubuntu_Fallback_764742', system-ui, arial;font-style: normal
}.__variable_764742 {--font-content: '__Ubuntu_764742', '__Ubuntu_Fallback_764742', system-ui, arial
}

@font-face {
ascent-override: 100%;
font-family: '__abcFavorit_38d951';
src: url(/_next/static/media/304c035dd6e7fc45-s.woff2) format('woff2');
font-display: swap;
font-weight: 400 700;
font-style: normal;
}

@font-face {
ascent-override: 100%;
font-family: '__abcFavorit_38d951';
src: url(/_next/static/media/af2d7ef9b353e758-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
ascent-override: 100%;
font-family: '__abcFavorit_38d951';
src: url(/_next/static/media/cab3700fee18b5d1-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
ascent-override: 100%;
font-family: '__abcFavorit_38d951';
src: url(/_next/static/media/224ae988fdf9f2c5-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__abcFavorit_Fallback_38d951';src: local("Arial");ascent-override: 90.97%;descent-override: 37.34%;line-gap-override: 0.00%;size-adjust: 104.43%
}.__className_38d951 {font-family: '__abcFavorit_38d951', '__abcFavorit_Fallback_38d951', system-ui, arial
}.__variable_38d951 {--font-content: '__abcFavorit_38d951', '__abcFavorit_Fallback_38d951', system-ui, arial
}

